<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#313189" />
      <div v-else>
      <!-- header section -->
      <section class="header header--portfolios">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div v-if="this.header_title.length > 0" v-html="$prismic.asHtml(this.header_title)" class="header__title"></div>
              <div v-if="this.header_text.length > 0" v-html="$prismic.asHtml(this.header_text)" class="header__text"></div>
            </div>
            <div class="col-12 col-lg-6">
              <div v-if="header_button_label.length > 0" class="header__box">
                <a class="btn main-button" :href="this.header_button_url.url">{{ $prismic.richTextAsPlain(this.header_button_label) }} <img src="@/assets/images/shared/yellow_arrow.svg" alt="arrow" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- portfolios section -->
      <section v-if="portfolios_gallery.length > 0" class="portfolios">
        <div class="container">
          <div v-for="(item, index) in this.portfolios_gallery" :key="`${index}`" data-aos="fade-up" class="portfolios__element">
            <div class="row">
              <div class="col-12 col-lg-6">
                <img class="img-fluid portfolios__image" :src="item.portfolio_image.url" :alt="item.portfolio_image.alt" />
              </div>
              <div class="col-12 col-lg-6 position-relative">
                <div v-html="$prismic.asHtml(item.portfolio_name)" class="portfolios__title"></div>
                <hr class="portfolios__line" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'PortfoliosPage',
  components: {
    Spinner
  },
  data: () => {
    return {
      cmsContentPending: false,
      header_title: null,
      header_text: null,
      header_button_label: null,
      header_button_url: null,
      portfolios_gallery: null
    }
  },
  methods: {
    async getPortfoliosContent () {
      await this.$prismic.client.getSingle('portfoliospage', { lang: this.$i18n.lang })
        .then((document) => {
          // header section
          this.header_title = document.data.header_title
          this.header_text = document.data.header_text
          this.header_button_label = document.data.header_button_label
          this.header_button_url = document.data.header_button_url
          // locations section
          this.portfolios_gallery = document.data.portfolios_gallery
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getPortfoliosContent()
  },
  watch: {
    '$i18n.locale': function (newValue) {
      this.getPortfoliosContent()
    }
  }
}
</script>

<style lang="scss">
  @import "./PortfoliosPage.scss";
</style>
